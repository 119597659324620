import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import ContentSection from '../components/ContentSection';
import FaqSection from '../components/FaqSection';
import Hero from '../components/Hero';
import {
	LawSquareColored,
	LightBulbSquareColored,
	RocketSquareColored,
	SproutSquareColored,
} from '../components/Icon';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import TestimonialSection from '../components/Testimonial';
import { Text, Title } from '../components/Text';
import BusinessInequalityAmico from '../images/business-inequality-amico.svg';
import ConsultingHeroImage from '../images/consulting-hero-image.svg';
import ContactUsAmico from '../images/contact-us-amico.svg';
import CourtRightsSvg from '../images/implememtation-consulting/court-rights.svg';
import ReadingListSvg from '../images/reading-list-amico-1.svg';
import { sprinkles } from '../sprinkles.css';

const StickyColumnSection = () => {
	const {
		i18n: { language },
	} = useTranslation();
	if (language === 'en') {
		return (
			<Section
				width="wide"
				direction="horizontal"
				padding
				gap="huge"
				align="stretch"
				wrap
			>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<Title level="subsection">
						Take the opportunity and secure a comprehensive whistleblower policy
						implementation consultation from <strong>konfidal</strong>!
					</Title>
					<Text>
						Contact us today to make an appointment for a non-binding consultation.
						Together we will find the best solution for your company to meet the
						requirements of the Whistleblower Protection Act and to act efficiently and
						economically at the same time.
					</Text>
					<Link
						className={ButtonRecipe({ variant: 'primary' })}
						to="https://my.meetergo.com/martinmeng/hinschg"
					>
						Contact us
					</Link>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<LawSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						The Konfidal introduction consultation helps you to clarify all questions
						around the law and its implementation in your company.
					</Title>
					<Text>
						Our experts support you in deciding whether you should operate your own
						reporting channel or use a SaaS solution. In doing so, we take into account
						legal aspects as well as the efficiency and cost-effectiveness of the
						respective solution.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<LightBulbSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						In addition, we offer you a comprehensive analysis of the necessary processes
						and procedures for the reporting channel and the reporting office.
					</Title>
					<Text>
						This includes staff training concepts, stakeholder engagement consultations,
						and implementation of data protection and privacy requirements.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<RocketSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Through the expertise of our advisors, you can minimise risks and act in
						compliance with the law while maintaining focus on your core business.
					</Title>
					<Text>
						With our professional implementation advice, you can get a detailed overview
						of your options and make informed decisions to find the most cost-effective
						and effective solution for your organisation.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<SproutSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Our advice not only helps you decide whether an internal or external service
						provider is the right choice, but also supports you in conducting cost-benefit
						analyses and long-term planning.
					</Title>
					<Text>Arrange a free initial consultation now.</Text>
					<Link to="https://my.meetergo.com/martinmeng/hinschg">Book a meeting</Link>
				</Section>
			</Section>
		);
	} else if (language === 'de') {
		return (
			<Section
				width="wide"
				direction="horizontal"
				padding
				gap="huge"
				align="stretch"
				wrap
			>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<Title level="subsection">
						Nutzen Sie die Gelegenheit und sichern Sie sich eine umfassende Beratung zur
						Implementierung des HinSchG mit <strong>konfidal</strong>!
					</Title>
					<Text>
						Kontaktieren Sie uns, um einen Termin für eine unverbindliche Beratung zu
						vereinbaren. Gemeinsam finden wir die beste Lösung für Ihr Unternehmen, um die
						Anforderungen des Whistleblower-Schutzgesetzes zu erfüllen und gleichzeitig
						effizient und wirtschaftlich zu handeln.
					</Text>
					<Link
						className={ButtonRecipe({ variant: 'primary' })}
						to="https://my.meetergo.com/martinmeng/hinschg"
					>
						Kontaktieren Sie uns
					</Link>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<LawSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Die Einführungsberatung von konfidal hilft Ihnen, alle Fragen rund um das
						Gesetz und dessen Umsetzung in Ihrem Unternehmen zu klären.
					</Title>
					<Text>
						Unsere Experten unterstützen Sie dabei zu entscheiden, ob Sie Ihren eigenen
						Meldekanal betreiben oder eine SaaS-Lösung nutzen sollten. Dabei
						berücksichtigen wir rechtliche Aspekte sowie die Effizienz und
						Wirtschaftlichkeit der jeweiligen Lösung.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<LightBulbSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Darüber hinaus bieten wir Ihnen eine umfassende Analyse der notwendigen
						Prozesse und Verfahren für den Meldekanal und die Meldestelle.
					</Title>
					<Text>
						Dies beinhaltet Konzepte zur Mitarbeitendenschulung, Beratungen zur Einbindung
						von Stakeholdern und die Umsetzung von Datenschutz- und
						Privatsphäre-Anforderungen.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<RocketSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Durch die Expertise unserer Berater können Sie Risiken minimieren und
						gesetzeskonform handeln, während Sie sich auf Ihr Kerngeschäft konzentrieren.
					</Title>
					<Text>
						Mit unserer professionellen Implementierungsberatung können Sie einen
						detaillierten Überblick über Ihre Optionen erhalten und fundierte
						Entscheidungen treffen, um die kosteneffektivste und zielgerichteste Lösung
						für Ihre Organisation zu finden.
					</Text>
				</Section>
				<Section
					direction="vertical"
					gap="medium"
					align="start"
					className={sprinkles({ flexBasis: 'four', flexGrow: '1' })}
				>
					<SproutSquareColored style={{ height: '64px', width: '64px' }} />
					<Title level="paragraph">
						Unsere Beratung hilft Ihnen nicht nur zu entscheiden, ob ein interner oder
						externer Dienstleister die richtige Wahl ist, sondern unterstützt Sie auch bei
						der Durchführung von Kosten-Nutzen-Analysen und langfristiger Planung.
					</Title>
					<Text>Vereinbaren Sie jetzt ein kostenloses Erstgespräch.</Text>
					<Link to="https://my.meetergo.com/martinmeng/hinschg">Termin buchen</Link>
				</Section>
			</Section>
		);
	}
	return null;
};

const ConsultingPage = ({
	data: { blogs, testimonialsData, faqData },
}: {
	data: {
		locales: { edges: Queries.LocaleEdge[] };
		blogs: { edges: Queries.MdxEdge[] };
		testimonialsData: { nodes: Queries.TestimonialsJson[] };
		faqData: { nodes: Queries.FaqJson[] };
	};
}) => {
	const a = testimonialsData.nodes.filter((t) => t !== null);
	const { t, i18n } = useTranslation('ConsultingPage');

	if (i18n.language === 'en')
		return (
			<Layout padding={false} limitWidth={false}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						width="wide"
						{...{
							title: <Title level="title">Your Whistleblowing Success Partner</Title>,
							text: "Are you a decision-maker or specialist looking for solutions to implement the EU Whistleblower Directive, but don't know where to start?",
							actions: [
								<Link
									key={1}
									to="https://my.meetergo.com/martinmeng/hinschg"
									className={ButtonRecipe({ size: 'medium', variant: 'primary' })}
								>
									Register for consultation
								</Link>,
							],
							image: (
								<img
									src={ConsultingHeroImage}
									alt="konfidal slogan 'fast simple compliant' surrounding a depictions of an ongoing meeting with one person persenting slides."
									width="100%"
									height="auto"
									className={sprinkles({
										width: 'fill',
										minWidth: {
											mobile: 'three',
											tablet: 'fourhalf',
										},
									})}
								/>
							),
						}}
					/>
					<ContentSection
						side="right"
						title={{
							__html:
								'With the introduction of whistleblower protection laws following the EU Whistleblower Directive, companies with 50 or more employees face new challenges.',
						}}
						titleLevel="subsection"
						imageAlt="Items of a courtroom"
						imageSrc={CourtRightsSvg}
					/>
					<ContentSection
						side="left"
						background="blue"
						title={{
							__html:
								'Implementing an internal reporting channel and reporting office is new territory for many companies and requires an exorbitant amount of time and money.',
						}}
						titleLevel="subsection"
						imageAlt="Person reading from a very long sheet of paper."
						imageSrc={ReadingListSvg}
					/>
					<ContentSection
						title={{
							__html:
								"But now you can just sit back and relax, because we've created a complete solution for you - <strong>konfidal</strong>.",
						}}
						text="Learn more during an introductory consultation with our experts."
						cta1Text="Make an appointment"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={BusinessInequalityAmico}
						imageAlt="One person can walk throught an open door labelled 'konfidal' while another person is blocked by a locked door without a label"
					/>
					<ContentSection
						side="right"
						background="blue"
						title="To ensure that you meet the legal requirements and make the right decision for your company, you should seek professional advice."
						titleLevel="paragraph"
						text={{
							__html:
								'Our <b>konfidal</b> <strong>introductory consultation</strong> on Whistleblower Laws offers&nbsp;you exactly this support.',
						}}
						cta1Text="Contact Us"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={ContactUsAmico}
						imageAlt="A customer support employee inside a smartphone"
					/>
					<StickyColumnSection />
					<TestimonialSection
						title="Testimonials"
						text="kofidal makes life easy"
						actions={[
							<Link key={0} to="https://my.meetergo.com/martinmeng/hinschg">
								Book consultation
							</Link>,
						]}
						testimonials={testimonialsData.nodes}
					/>
					<FaqSection
						content={faqData.nodes[0].entries.filter((e) =>
							e.pages.includes('/consulting'),
						)}
						width="wide"
					/>
				</Section>
			</Layout>
		);
	if (i18n.language === 'de')
		return (
			<Layout padding={false} limitWidth={false}>
				<Section direction="vertical" width="fill" gap="giant">
					<Hero
						width="wide"
						{...{
							title: <Title level="title">HinSchG ist da... Aber wie umsetzen?</Title>,
							text: 'Sie sind mit der Umsetzung des Hinweisgeberschutzgesetzes in Ihrem Unternehmen betraut und fragen sich, wo Sie anfangen sollen? Im Netz finden sich viele Infos, aber nichts ist praxistauglich oder passt zu Ihrer Organisation? Wir beraten Sie ergebnisoffen!',
							actions: [
								<Link
									key={1}
									to="https://my.meetergo.com/martinmeng/hinschg"
									className={ButtonRecipe({ size: 'medium', variant: 'primary' })}
								>
									Kostenloses Erstgespräch buchen
								</Link>,
							],
							image: (
								<img
									src={ConsultingHeroImage}
									alt="Slogan 'einfach. schnell. rechtskonform' von Konfidal, umgeben von einer Darstellung eines laufenden Meetings mit einer Person, die Folien präsentiert."
									width="100%"
									height="auto"
									className={sprinkles({
										width: 'fill',
										minWidth: {
											mobile: 'three',
											tablet: 'fourhalf',
										},
									})}
								/>
							),
						}}
					/>
					<ContentSection
						side="right"
						title={{
							__html:
								'Mit der Einführung des Hinweisgeberschutzgesetz nach der EU-Hinweisgeberrichtlinie stehen Unternehmen mit 50 oder mehr Mitarbeitern vor neuen Herausforderungen.',
						}}
						titleLevel="subsection"
						imageAlt="Gegenstände eines Gerichtssaals"
						imageSrc={CourtRightsSvg}
					/>
					<ContentSection
						side="left"
						background="blue"
						title={{
							__html:
								'Die Einrichtung eines internen Meldekanals und einer Meldestelle ist für viele Unternehmen Neuland und erfordert einen exorbitanten Aufwand an Zeit und Geld.',
						}}
						titleLevel="subsection"
						imageAlt="Person, die von einem sehr langen Blatt Papier liest."
						imageSrc={ReadingListSvg}
					/>
					<ContentSection
						title={{
							__html:
								'Damit Sie nicht Stunden über Stunden in die Recherche nach Umsetzungsmöglichkeiten des Hinweisgeberschutzes investieren müssen',
						}}
						text="...gehen wir mit Ihnen strukturiert und mit unserem Beratungswissen aus der Praxis Ihre einzigartigen betrieblichen Voraussetzungen durch. Am Ende des Prozesses haben Sie einen klaren 'go to action'-Plan, wie Sie schnell, kostengünstig und ohne Friktionen zu einer rechtskonformen Umsetzung der Anforderungen kommen."
						cta1Text="Jetzt Termin vereinbaren"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={BusinessInequalityAmico}
						imageAlt="Eine Person kann durch eine offene Tür mit dem Aufdruck 'konfidal' gehen, während eine andere Person durch eine verschlossene Tür ohne Beschriftung blockiert wird"
					/>
					<ContentSection
						side="right"
						background="blue"
						title="Um sicherzustellen, dass Sie die gesetzlichen Anforderungen erfüllen und die richtige Entscheidung für Ihr Unternehmen treffen, sollten Sie professionellen Rat suchen."
						titleLevel="paragraph"
						text={{
							__html:
								'Die <b>konfidal</b> <strong>Einführungsberatung</strong> zum Whistleblower-Gesetz bietet Ihnen genau diese Unterstützung.',
						}}
						cta1Text="Kontaktieren Sie uns"
						cta1Link="https://my.meetergo.com/martinmeng/hinschg"
						imageSrc={ContactUsAmico}
						imageAlt="Ein Kundensupport-Mitarbeiter in einem Smartphone"
					/>
					<StickyColumnSection />
					<TestimonialSection
						title="Referenzen"
						text="konfidal macht das Leben einfach"
						actions={[
							<Link key={0} to="https://my.meetergo.com/martinmeng/hinschg">
								Beratung buchen
							</Link>,
						]}
						testimonials={testimonialsData.nodes}
					/>
					<FaqSection
						content={faqData.nodes[0].entries.filter((e) =>
							e.pages.includes('/consulting'),
						)}
						width="wide"
					/>
				</Section>
			</Layout>
		);
};
export default ConsultingPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		blogs: allMdx(
			filter: {
				internal: { contentFilePath: { glob: "**/blog/**" } }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						published_at
						path
						author
						featured_image {
							src {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							alt
						}
					}
					excerpt(pruneLength: 250)
					internal {
						contentFilePath
					}
				}
			}
		}
		faqData: allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
		testimonialsData: allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["consider-it"] } }
		) {
			nodes {
				name
				company
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				text
				personImageAlt
				companyLogoAlt
				link
			}
		}
	}
`;
